<template>
  <div>
    <el-dialog
      title="资源资产流转审批"
      :visible.sync="dialogVisible"
      width="100%"
      center
      :before-close="handleClose"
    >
      <!-- 项目详情 -->
      <div class="project-detail">
        <div class="define-table">
          <table style="table-layout: fixed; border: 1px">
            <tr>
              <td rowspan="2">流入方</td>
              <td>流入方</td>
              <td>{{ projectInfo.lrf ? projectInfo.lrf : "" }}</td>
              <td>单位性质</td>
              <td colspan="3">{{ projectInfo.res ? projectInfo.res : "" }}</td>
            </tr>
            <tr>
              <td>法定代表人</td>
              <td>
                {{ projectInfo.lrfBossName ? projectInfo.lrfBossName : "" }}
              </td>
              <td>身份证号</td>
              <td>{{ projectInfo.lrfIdCard ? projectInfo.lrfIdCard : "" }}</td>
              <td>联系方式</td>
              <td>{{ projectInfo.lrfPhone ? projectInfo.lrfPhone : "" }}</td>
            </tr>
            <tr>
              <td rowspan="2">流转信息汇总</td>
              <td>总面积</td>
              <td>{{ projectInfo.sumArea ? projectInfo.sumArea : "" }}亩</td>
              <td>成交总金额</td>
              <td colspan="3">
                {{ projectInfo.sumPrice ? projectInfo.sumPrice : "" }}元
              </td>
            </tr>
            <tr>
              <td>农地性质</td>
              <td colspan="5">
                <span>
                  {{
                    projectInfo.lcfList
                      ? [
                          ...new Set(
                            projectInfo.lcfList.map((item) => cqlx(item.cqlxbh))
                          ),
                        ].join(", ")
                      : ""
                  }}
                </span>

                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="耕地"></el-checkbox>
                  <el-checkbox label="园地"></el-checkbox>
                  <el-checkbox label="养殖水地"></el-checkbox>
                  <el-checkbox label="禽畜饲养用地" disabled></el-checkbox>
                  <el-checkbox label="设施农业用地" disabled></el-checkbox>
                  <el-checkbox label="四荒地" disabled></el-checkbox>
                  <el-checkbox label="其他" disabled></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="project-detail">
        <h3>流转详情</h3>
        <div
          class="tableTop"
          v-for="(item, index) in projectInfo.lcfList"
          :key="index"
        >
          <table style="table-layout: fixed; border: 1px">
            <tr>
              <td rowspan="2">
                <p>流出方</p>
                <p style="color: #169bd5" @click="HTVisibleFn(item)">
                  查看合同
                </p>
              </td>
              <td>流出方</td>
              <td>{{ item.lcf ? item.lcf : "" }}</td>
              <td>家庭住址</td>
              <td colspan="3">{{ item.address ? item.address : "" }}</td>
            </tr>
            <tr>
              <td>
                <p>法定代表人</p>
                <p>(负责人/农户代表人/)</p>
              </td>
              <td>{{ item.lcfBossName ? item.lcfBossName : "" }}</td>
              <td>身份证号</td>
              <td>{{ item.lcfIdCard ? item.lcfIdCard : "" }}</td>
              <td>联系方式</td>
              <td>{{ item.lcfPhone ? item.lcfPhone : "" }}</td>
            </tr>
            <tr>
              <td rowspan="7">流转内容及方式</td>
              <td>所有权人</td>
              <td colspan="5">{{ item.owner ? item.owner : "" }}</td>
            </tr>
            <tr>
              <td>坐落位置</td>
              <td colspan="5">{{ item.location ? item.location : "" }}</td>
            </tr>
            <tr>
              <td>农地权属</td>
              <td colspan="5">
                {{ item.landType ? item.landType : "" }}
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="国有"></el-checkbox>
                  <el-checkbox label="集体所有"></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
            <tr>
              <td>农地性质</td>
              <td colspan="5">
                {{ cqlx(item.cqlxbh) }}
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox label="耕地"></el-checkbox>
                  <el-checkbox label="园地"></el-checkbox>
                  <el-checkbox label="养殖水地"></el-checkbox>
                  <el-checkbox label="禽畜饲养用地" disabled></el-checkbox>
                  <el-checkbox label="设施农业用地" disabled></el-checkbox>
                  <el-checkbox label="四荒地" disabled></el-checkbox>
                  <el-checkbox label="其他" disabled></el-checkbox>
                </el-checkbox-group> -->
              </td>
            </tr>
            <tr>
              <td>流转面积</td>
              <td colspan="2">{{ item.flowArea ? item.flowArea : "" }}亩</td>
              <td>成交金额</td>
              <td colspan="2">{{ item.cjPrice ? item.cjPrice : "" }}元</td>
            </tr>
            <tr>
              <td>合同签署日期</td>
              <td colspan="2">
                {{ item.contractSignDate ? item.contractSignDate : "" }}
              </td>
              <td>合同编号</td>
              <td colspan="2">
                {{ item.contractCode ? item.contractCode : "" }}
              </td>
            </tr>
            <tr>
              <td>合同起止日期</td>
              <td colspan="5">
                {{ item.contractStartDate ? item.contractStartDate : "" }}
                <!-- <el-form>
                  <el-form-item>
                    <el-date-picker
                      v-model="time"
                      placeholder="选择开始日期"
                      size="small"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      @change="changeTime"
                    >
                    </el-date-picker> -->
                至
                {{ item.contractEndDate ? item.contractEndDate : "" }}
                <!-- <el-date-picker
                      v-model="time1"
                      placeholder="选择结束日期"
                      align="right"
                      size="small"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      @change="changeTime1"
                    >
                    </el-date-picker>
                        </el-form-item>
                </el-form>
                     -->
                ,共计{{ item.lznx ? item.lznx : "" }}年
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="bottomBtn" v-if="transact">
        <button class="backBtn" @click="tuihui">退回</button>
        <button class="submitBtn" @click="tongyi">符合规定，同意流转</button>
      </div>
    </el-dialog>
    <!-- 查看合同弹窗 -->
    <el-dialog
      title="查看合同"
      :visible.sync="HTVisible"
      width="50%"

      append-to-body
      :before-close="handleClose"
    >
      <!-- 展示上传的图片 -->
      <div>
        <div class="tabs">
          <div v-for="(item, index) in tabList" :key="index">
            <span @click="detailBtn(index)">{{ item.title }}</span>
          </div>
          <!-- <div v-for="(item,index) in 5" :key="index">
            {{ index }}
          </div> -->
        </div>
        <div class="ShowList" v-if="fileList.length">
          <div class="innerTable" style="display: flex">
            <div
              v-for="(file, index) in fileList"
              :key="index"
              style="
                display: flex;
                /* flex-direction: column; */
                align-items: center;
                padding: 10px;
              "
            >
              <!-- <span>{{ file.FilesName }}</span> -->
              <el-image
                ref="preview"
                style="width: 115px; height: 115px"
                class="el-upload-list__item-thumbnail"
                :src="file.imgUrl ? file.imgUrl : file"
                :preview-src-list="innerTableList"
                alt="图片无法加载"
                :initial-index="index"
              />
            </div>
          </div>
        </div>
        <div v-else style="width: 100%; text-align: center">暂无数据</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="HTVisible = false">关 闭</el-button>
        <!-- <el-button type="primary" @click="HTVisible = false">确 定</el-button> -->
      </span>
    </el-dialog>

    <el-dialog
      title="审批不通过"
      :visible.sync="onshow"
      width="30%"
      append-to-body
    >
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="title"
          >
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onshow = false">取 消</el-button>
        <el-button type="primary" @click="reject">退 回</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审批通过"
      :visible.sync="showy"
      width="20%"
      append-to-body
    >
      <!-- 展示上传的图片 -->
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr
              ref="qrCode"
              :text="text"
              :logoSrc="logoSrc"
              :color-dark="randomColor"
              :callback="qrCodeCallback"
              width="200"
              height="200"
            ></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
              重新生成
            </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showy = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  selectCirculationApproval,
  rejectWorkflow,
  selectBackUpContract,
  selectApprovalOtherMaterials,
} from "@/api/spgl";
// import { log } from "console";
import { getLzlx, getCqlx } from "../../../util/types";
// import { title } from "process";
//数据总线
import { Utils } from "@/assets/js/util.js";
import VueQr from "vue-qr";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api;
export default {
  data() {
    return {
      text: window.globalUrl.api,
      logoSrc: "",
      randomColor: "black",
      qrCodeUrl: "", // 存放生成的二维码url
      isValid: true, // 添加一个状态来跟踪二维码是否有效
      taskId: "", // 查询id
      identityId: "", // 身份id identityObj.content.identityId;

      dataobj: {}, // 存放详情数据
      tabList: [
        {
          title: "查看合同",
          index: "1",
        },
        {
          title: "其他附件",
          index: "2",
        },
      ],
      time: "",
      time1: "",
      title: "", // 审批退回不通过陈述
      idea: "符合规定，同意流转", // 审批通过意见
      dialogVisible: true,
      onshow: false, // 退回弹窗
      showy: false, // 同意弹窗
      projectInfo: {}, // 详情信息
      // checkList: ["选中且禁用", "复选框 A"],
      HTVisible: false,
      baseUrl: window.globalUrl.BASE_API,
      fileList: [],
      innerTableList: [],
      transact: false,
      HTVlist: {},
    };
  },
  components: {
    VueQr,
  },
  computed: mapGetters(["userInfo", "access_token", "identityObj"]),
  created() {
    var that = this;
    Utils.$off("dbspDetails");
    Utils.$on("dbspDetails", function (e) {
      console.log("44454", e.data);
      console.log(JSON.parse(e.data));
      let res = JSON.parse(e.data);
      if (res.content == "已收到审批进程结束") {
        that.showy = false;
        that.handleClose();
      }
      // that.updateChat(e);
    });
  },
  methods: {
    async detailBtn(index) {
      console.log(index);
      this.innerTableList = [];
      this.fileList = [];
      if (index == 0) {
        this.showFile();
      } else if (index == 1) {
        this.showPic();
      }
    },
    // 查看合同
    async HTVisibleFn(item) {
      this.HTVisible = true;
      this.HTVlist = item;
      this.showFile();
    },

    // 查看文件
    async showFile(item) {
      let res = await selectBackUpContract({
        approvalId: this.HTVlist.approvalId,
        xmbh: this.HTVlist.xmbh,
        // approvalId: '20245117220001',
        // xmbh: 'CYC202310160152',
      });
      res = res.data ? JSON.parse(res.data.contractPicUrl) : {};
      this.fileList = res.map((item) => {
        return {
          ...item,
          imgUrl: `${baseUrl}/admin/file/get?ossFilePath=${item.imgUrl}`,
        };
      });
      this.innerTableList = this.fileList.map((item) => {
        return `${baseUrl}/admin/file/get?ossFilePath=${item.imgUrl}`;
      });
    },

    // 查看照片
    async showPic(item) {
      let res = await selectApprovalOtherMaterials({
        approvalId: this.HTVlist.approvalId,
        xmbh: this.HTVlist.xmbh,
      });

      const otherMaterials = res.data.otherFiles.split(",").map(url => url.trim());
      console.log("455555",otherMaterials);

      this.fileList = otherMaterials.map((item) => {
        return {
          imgUrl: `${baseUrl}/admin/file/get?ossFilePath=${item}`,
        };
      });
      this.innerTableList = otherMaterials.map((item) => {
        return `${baseUrl}/admin/file/get?ossFilePath=${item}`;
      });
      console.log(this.innerTableList);
    },
    webSocketOnOpen(e) {
      console.log("与服务端连接打开->", e);
      if (this.webSocketObject.readyState == this.webSocketObject.CONNECTING) {
        console.log("连接正在打开");
      }
      if (this.webSocketObject.readyState == this.webSocketObject.OPEN) {
        console.log("连接已打开");
      }
      //开启心跳
      // this.start();
    },
    // 消息监听
    webSocketOnMessage(e) {
      if (JSON.parse(e.data).type == "agreeProcess") {
        console.log("这是最新的消息", e);
        // this.updateChat(e);
      }
    },

    callApiOnBlur() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      // console.log("identityObj", identityObj.content.identityId);
      this.identityId = identityObj.content.identityId;
      this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.dataobj.instanceProcessId}/agreeReason=${this.idea}/identityId=${this.identityId}`;
    },
    // resetQRCodeStatus() {
    //   setTimeout(() => {
    //     this.isValid = false; // 将 isValid 设置为有效
    //     console.log("二维码状态已恢复为有效");
    //   }, 100); // 1秒后恢复有效状态
    // },
    // 处理二维码回调，设置二维码的URL
    qrCodeCallback(url) {
      this.qrCodeUrl = url;
    },
    regenerateQRCode() {
      this.isValid = false; // 设置状态为有效
    },

    // 拒绝
    async reject(row) {
      let res = await rejectWorkflow({
        processInstanceId: this.dataobj.instanceProcessId,
        rejectReason: this.title,
        taskId: this.taskId,
      });
      console.log(res);
      if (res.code == 0) {
        this.title = "";
        this.onshow = false;
        this.$message.success("退回成功");
        this.handleClose();
        // this.resetQRCodeStatus(); // 重置二维码状态
      } else {
        this.$message.error("退回失败");
      }
    },
    // 接收参数
    receiveData(data, Boolean) {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      console.log("identityObj", identityObj.content.identityId);
      this.identityId = identityObj.content.identityId;
      this.taskId = data.taskId;
      this.dataobj = data;
      this.transact = Boolean;
      this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.dataobj.instanceProcessId}/agreeReason=${this.idea}/identityId=${this.identityId}`;
      this.details();
      console.log("4444444444444444444", data);
    },
    // 详情
    async details() {
      let res = await selectCirculationApproval({
        taskId: this.taskId,
        // taskId: "d5dd5c6e-50a3-11ef-b412-005056c00008",
      });
      this.projectInfo = res.data ? res.data : {};
      console.log(res);
    },
    lzlx(type) {
      let res = getLzlx(type);
      return res;
    },
    cqlx(type) {
      console.log("type", type);
      let list = JSON.parse(localStorage.getItem("types"));
      let res = "";
      console.log(list);
      if (list && list.length > 0) {
        let res = getCqlx(type, list);
        console.log(res);
        return res;
      } else {
        return "";
      }
    },

    handleClose() {
      this.$emit("closeDialog", false);
    },

    // 退回
    tuihui() {
      this.onshow = true;
    },
    // 同意
    tongyi() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      console.log("identityObj", identityObj.content.identityId);
      this.identityId = identityObj.content.identityId;
      this.showy = true;
      this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.dataobj.instanceProcessId}/agreeReason=${this.idea}/identityId=${this.identityId}`;
      console.log("同意", this.text);
    },

    // 关闭弹框
    closeDialog() {
      this.isShow = false;
    },
    // 切换时间
    changeTime(val) {
      console.log(val);
      // if (val == 1) {
      this.startTime = val;
      // } else {
      //   this.endTime = val[1];
      // }
    },
    // 切换时间
    changeTime1(val) {
      console.log(val);
      // if (val == 1) {
      //   this.startTime = val[0];
      // } else {
      this.endTime = val;
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.tongyi {
  box-sizing: border-box;
  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }
    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.tuihui {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  .leftbox {
    width: 20%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 24px;
    border-right: 1px solid #000;
  }
  .rightbox {
    width: 80%;
    height: 200px;

    // border-right: 1px solid #000;
  }
}
.project-detail {
  width: 1200px;
  margin: 0 auto;
  background: #fff;
  text-align: left;
  padding-bottom: 50px;

  .define-tableDetails {
    margin-top: 50rpx;
  }
  table {
    width: 100%;

    border-collapse: collapse; /* 合并线段 */
  }

  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }
  .tableTop {
    padding-top: 10px;
  }
}
.bottomBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .backBtn {
    width: 100px;
    height: 40px;
    color: #fff;
    background: #f7c6cc;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .submitBtn {
    width: 150px;
    height: 40px;
    display: flex;
    color: #fff;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    background: #02a7f0;
    border-radius: 50px;
  }
}
.tabs {
  width: 100%;
  height: 100rpx;
  display: flex;
  margin: 20px 0px;

  span {
    margin-right: 20px;
    border: solid 2px #ccc;
    padding: 5px 5px;
    cursor: pointer;
  }
}
.ShowList {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    tr {
      td {
        .box {
          width: 150px;
          height: 100px;
          overflow: hidden;
          padding: 5px 5px;
          .Imgs {
            width: 232px;
            .el-image {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
